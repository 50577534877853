import {AfterViewInit, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ChatbotRestService} from '../core/services/api/chatbot.rest-service';
import {DOCUMENT} from '@angular/common';
import {Observable, Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {ScriptService} from '../core/services/utility/scripts.service';
import {ChatbotService} from '../core/services/chatbot.service';

declare let require: any;

@Component({
    selector: 'app-chatbot',
    templateUrl: './chatbot.component.html',
    styleUrls: ['./chatbot.component.scss']
})
export class ChatbotComponent implements OnInit, AfterViewInit, OnDestroy {
    private ngUnsubscribe$ = new Subject<any>();
    @ViewChild('chatbot') chatbot!: ElementRef;
    private webchat: any;
    private webby: any;
    token: string;
    size: string;
    full = false;
    public open = 'open';

    @HostListener('window:resizeChat', [ '$event' ])
    resizeHandler(event) {
        this.open = 'closed';
        if (event.detail.size == 'full') {
            this.size = 'fa-window-minimize'
        }
    }

    constructor(
        private chatbotRestService: ChatbotRestService,
        private chatbotService: ChatbotService,
        private scriptService: ScriptService,
        @Inject(DOCUMENT) private document
    ) {
        this.chatbotService.size.subscribe(value => {
            console.log('resizing in chat');
            console.log(value);
        }, error =>{
            console.log(error);
        });

        this.chatbotRestService.getChatbotToken().pipe(takeUntil(this.ngUnsubscribe$)).subscribe((response) => {
            if (response.returnObject) {
                this.token = response.returnObject;
            }
        });
    }


    ngAfterViewInit() {
        this.scriptService.loadScript('tcom').then(webby => {
            this.scriptService.loadScript('chatbot').then(chatty => {
                const s2 = this.document.createElement('script');
                const node = this.document.createTextNode('newWebchat("'+ this.token +'")');
                s2.appendChild(node);
                this.chatbot?.nativeElement.appendChild(s2);
            });
        });
    }

    ngOnInit(): void {
    }

    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    }

    maximize() {
        this.full = false;
    }

    minimize() {
        this.full = true;
    }

}
