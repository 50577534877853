import {ProductsResponse} from '../../models/shop/products-response.model';
import {HttpErrorResponse} from '@angular/common/http';
import {ProductRequest} from '../../models/shop/product-request.model';
import {Product} from '../../models/shop/product.model';
import {PromoActions, PromoActionTypes} from '../actions/promo.actions';
import {CartActions, CartActionTypes} from '../actions/cart.actions';

export interface State {
    promoResponse: ProductsResponse;
    promoRequest: ProductRequest;
    promoErrorResponse: HttpErrorResponse;
    promoLoading: boolean;
    promoProducts: Product[];
    promoProductCache: Product[];
    promoScrollPosition: number;
    promoCart: any;
}

export const initialState: State = {
    promoResponse: null,
    promoRequest: null,
    promoErrorResponse: null,
    promoLoading: false,
    promoProducts: [],
    promoProductCache: [],
    promoScrollPosition: 0,
    promoCart: {}
};

export function reducerCart(state = initialState, action: CartActions): State{
    switch (action.type) {
        case CartActionTypes.ADD_CART_ITEM_ACTION:
            if(action.payload.length === 0){
                return state;
            }

            return {
                ...state,
                promoCart: Object.keys(state.promoCart)
                    .filter(id => id !== action.payload[0].product.id + action.payload[0].product.campaignId)
                    .reduce((obj, id) => {
                        obj[id] = state.promoCart[id];
                        return obj;
                    }, {})
            }
        default:
            return state;

    }
}

export function reducer(state = initialState, action: PromoActions) {
    switch (action.type) {
        case PromoActionTypes.PROMO_LOAD:
            return {
                ...state,
                promoRequest: action.payload,
                promoLoading: true
            };
        case PromoActionTypes.PROMO_LOAD_COMPLETE:
            return {
                ...state,
                promoProductCache: [...state.promoProductCache, ...action.payload.products],
                promoResponse: action.payload,
                promoLoading: false
            };
        case PromoActionTypes.PROMO_LOAD_FAIL:
            return {
                ...state,
                promoErrorResponse: action.payload,
                promoLoading: false
            };
        case PromoActionTypes.PROMO_SEARCH:
            return {
                ...state,
                promoProducts: [],
                promoProductCache: [],
                promoLoading: true
            };
        case PromoActionTypes.PROMO_SCROLL_POSITION:
            return {
                ...state,
                promoScrollPosition: action.payload
            };
        case PromoActionTypes.PROMO_BATCH_FROM_CACHE:
            return {
                ...state,
                promoProducts: [...state.promoProducts, ...state.promoProductCache.slice(0, 800)],
                promoProductCache: state.promoProductCache.slice(800)
            };
        case PromoActionTypes.PROMO_UPDATE_CART:
            return {
                ...state,
                promoCart: {...state.promoCart, ...{[action.payload.id]: action.payload.cartItem}}
            };
        case PromoActionTypes.PROMO_EMPTY_CART:
            const newPromoCart = Object.keys(state.promoCart)
                .filter(pfid => pfid.indexOf(action.payload) < 0)
                .reduce((newCart, pfid) => {
                    newCart[pfid] = state.promoCart[pfid];
                    return newCart;
                }, {});

            return {
                ...state,
                promoCart: newPromoCart
            };
        default:
            return state;
    }
}

export const getPromoLoading = (state: State) => state.promoLoading;
export const getPromoRequest = (state: State) => state.promoRequest;
export const getPromoResponse = (state: State) => state.promoResponse;
export const getPromoErrorResponse = (state: State) => state.promoErrorResponse;
export const getPromoProductCache = (state: State) => state.promoProductCache;
export const getPromoProducts = (state: State) => state.promoProducts;
export const getPromoScrollPosition = (state: State) => state.promoScrollPosition;
export const getPromoCart = (state: State) => state.promoCart;
export const getPromoCampaignInfo = (state: State) => state.promoResponse.campaignInfo;
