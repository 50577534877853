import {Action} from '@ngrx/store';
import {HttpErrorResponse} from '@angular/common/http';

export enum UserActionTypes {
    // TODO message case should be beautified
    LOAD_USER = '[User] Load User',
    LOAD_USER_COMPLETE = '[User] Load User Complete',
    LOAD_USER_FAIL = '[User] Load User FAIL',
    LOAD_NAV = '[User] Load Nav',
    LOAD_NAV_COMPLETE = '[User] Load Nav Complete',
    LOAD_NAV_FAIL = '[User] Load Nav FAIL',
    LOAD_USER_IDFS = '[User] Load User IDFS',
    LOAD_USER_IDFS_COMPLETE = '[User] Load User IDFS complete',
    LOAD_USER_IDFS_FAIL = '[User] Load User IDFS FAIL',
    LOAD_USER_MAIN_IDF = '[User] Load User Main IDF',
    LOAD_USER_MAIN_IDF_COMPLETE = '[User] Load User Main IDF complete',
    LOAD_USER_MAIN_IDF_FAIL = '[User] Load User Main IDF FAIL',
    LOAD_USER_VZS = '[User] Load User VZS',
    LOAD_USER_VZS_COMPLETE = '[User] Load User VZS complete',
    LOAD_USER_VZS_FAIL = '[User] Load User VZS FAIL',
    LOAD_USER_SA = '[User] Load User Shipping Adresses',
    LOAD_USER_SA_COMPLETE = '[User] Load User Shipping Adresses complete',
    LOAD_USER_SA_FAIL = '[User] Load User Shipping Adresses FAIL',
    LOAD_USER_DISTRIBUTION_CENTER_ADRESS = '[User] Load User Distribution Center Adress',
    LOAD_USER_DISTRIBUTION_CENTER_ADRESS_COMPLETE = '[User] Load User Distribution Center Adress complete',
    LOAD_USER_DISTRIBUTION_CENTER_ADRESS_FAIL = '[User] Load User Distribution Center Adress FAIL',
    LOAD_ALL_USER_DISTRIBUTION_CENTER_ADRESS = '[User] Load all User Distribution Center Adress',
    LOAD_ALL_USER_DISTRIBUTION_CENTER_ADRESS_COMPLETE = '[User] Load all User Distribution Center Adress complete',
    LOAD_ALL_USER_DISTRIBUTION_CENTER_ADRESS_FAIL = '[User] Load all User Distribution Center Adress FAIL',
    LOAD_AUTH_TOKEN = '[User] Load User auth token',
    LOAD_AUTH_TOKEN_COMPLETE = '[User] Load User auth token complete',
    LOAD_AUTH_TOKEN_FAIL = '[User] Load User auth token FAIL',
    LOAD_AUTH_TOKENS = '[User] Load User auth tokens',
    LOAD_AUTH_TOKENS_COMPLETE = '[User] Load User auth tokens complete',
    LOAD_AUTH_TOKENS_FAIL = '[User] Load User auth tokens FAIL',
    LOGOUT = '[User] Logout'
}

export class LoadUserAction implements Action {
    readonly type = UserActionTypes.LOAD_USER;

    constructor() { // == Query
    }
}

export class LoadUserCompleteAction implements Action {
    readonly type = UserActionTypes.LOAD_USER_COMPLETE;

    constructor(public payload: any) {
    }
}

export class LoadUserFailAction implements Action {
    readonly type = UserActionTypes.LOAD_USER_FAIL;

    constructor(public payload: HttpErrorResponse) {
    }
}


export class LoadNavAction implements Action {
    readonly type = UserActionTypes.LOAD_NAV;

    constructor() {
    }
}

export class LoadNavCompleteAction implements Action {
    readonly type = UserActionTypes.LOAD_NAV_COMPLETE;

    constructor(public payload: any) {
    }
}

export class LoadNavFailAction implements Action {
    readonly type = UserActionTypes.LOAD_NAV_FAIL;

    constructor(public payload: any) {
    }
}

export class LoadUserIDFsAction implements Action {
    readonly type = UserActionTypes.LOAD_USER_IDFS;

    constructor(public payload: any) { // == Query
    }
}

export class LoadUserIDFsCompleteAction implements Action {
    readonly type = UserActionTypes.LOAD_USER_IDFS_COMPLETE;

    constructor(public payload: any) { // == Query
    }
}

export class LoadUserIDFsFailAction implements Action {
    readonly type = UserActionTypes.LOAD_USER_IDFS_FAIL;

    constructor(public payload: any) { // == Query
    }
}

export class LoadUserMainIdfAction implements Action {
    readonly type = UserActionTypes.LOAD_USER_MAIN_IDF;

    constructor() {
    }
}

export class LoadUserMainIdfCompleteAction implements Action {
    readonly type = UserActionTypes.LOAD_USER_MAIN_IDF_COMPLETE;

    // TODO check whether payload could be string for type safety
    constructor(public payload: any) {
    }
}

export class LoadUserMainIdfFailAction implements Action {
    readonly type = UserActionTypes.LOAD_USER_MAIN_IDF_FAIL;

    constructor(public payload: HttpErrorResponse) {
    }
}

export class LoadUserVZsAction implements Action {
    readonly type = UserActionTypes.LOAD_USER_VZS;

    constructor(public payload: string) { // == Query
    }
}

export class LoadUserVZsCompleteAction implements Action {
    readonly type = UserActionTypes.LOAD_USER_VZS_COMPLETE;

    constructor(public payload: any, public idf: string) { // == Query
    }
}

export class LoadUserVZsFailAction implements Action {
    readonly type = UserActionTypes.LOAD_USER_VZS_FAIL;

    constructor(public payload: HttpErrorResponse) { // == Query
    }
}

export class LoadUserShippingAdressesAction implements Action {
    readonly type = UserActionTypes.LOAD_USER_SA;

    constructor() { // == Query
    }
}

export class LoadUserShippingAdressesCompleteAction implements Action {
    readonly type = UserActionTypes.LOAD_USER_SA_COMPLETE;

    constructor(public payload: any) { // == Query
    }
}

export class LoadUserShippingAdressesFailAction implements Action {
    readonly type = UserActionTypes.LOAD_USER_SA_FAIL;

    constructor(public payload: HttpErrorResponse) { // == Query
    }
}

export class GetMainDistributionCentersAdressAction implements Action {
    readonly type = UserActionTypes.LOAD_USER_DISTRIBUTION_CENTER_ADRESS;

    constructor() { // == Query
    }
}

export class GetMainDistributionCentersAdressCompleteAction implements Action {
    readonly type = UserActionTypes.LOAD_USER_DISTRIBUTION_CENTER_ADRESS_COMPLETE;

    constructor(public payload: any) { // == Query
    }
}

export class GetMainDistributionCentersAdressFailAction implements Action {
    readonly type = UserActionTypes.LOAD_USER_DISTRIBUTION_CENTER_ADRESS_FAIL;

    constructor(public payload: HttpErrorResponse) { // == Query
    }
}

export class GetAllDistributionCentersAdressAction implements Action {
    readonly type = UserActionTypes.LOAD_ALL_USER_DISTRIBUTION_CENTER_ADRESS;

    constructor() { // == Query
    }
}

export class GetAllDistributionCentersAdressCompleteAction implements Action {
    readonly type = UserActionTypes.LOAD_ALL_USER_DISTRIBUTION_CENTER_ADRESS_COMPLETE;

    constructor(public payload: any) { // == Query
    }
}

export class GetAllDistributionCentersAdressFailAction implements Action {
    readonly type = UserActionTypes.LOAD_ALL_USER_DISTRIBUTION_CENTER_ADRESS_FAIL;

    constructor(public payload: HttpErrorResponse) { // == Query
    }
}

export class LoadAuthTokenAction implements Action {
    readonly type = UserActionTypes.LOAD_AUTH_TOKEN;

    constructor() {
    }
}

export class LoadAuthTokenCompleteAction implements Action {
    readonly type = UserActionTypes.LOAD_AUTH_TOKEN_COMPLETE;

    // TODO check whether payload could be string for type safety
    constructor(public payload: any) {
    }
}

export class LoadAuthTokenFailAction implements Action {
    readonly type = UserActionTypes.LOAD_AUTH_TOKEN_FAIL;

    constructor(public payload: HttpErrorResponse) {
    }
}

export class LoadAuthTokensAction implements Action {
    readonly type = UserActionTypes.LOAD_AUTH_TOKENS;

    constructor() {}
}

export class LoadAuthTokensCompleteAction implements Action {
    readonly type = UserActionTypes.LOAD_AUTH_TOKENS_COMPLETE;

    constructor(public payload: any) {
    }
}

export class LoadAuthTokensFailAction implements Action {
    readonly type = UserActionTypes.LOAD_AUTH_TOKENS_FAIL;

    constructor(public payload: HttpErrorResponse) {
    }
}


export class UserLogoutAction implements Action {
    readonly type = UserActionTypes.LOGOUT;

    constructor() { // == Query
        localStorage.removeItem('token');
    }
}

export type UserActions =
    LoadUserAction |
    LoadUserCompleteAction |
    LoadUserFailAction |
    LoadNavAction |
    LoadNavCompleteAction |
    LoadNavFailAction |
    LoadUserIDFsAction |
    LoadUserIDFsCompleteAction |
    LoadUserIDFsFailAction |
    LoadUserMainIdfAction |
    LoadUserMainIdfCompleteAction |
    LoadUserMainIdfFailAction |
    LoadUserVZsAction |
    LoadUserVZsCompleteAction |
    LoadUserVZsFailAction |
    LoadUserShippingAdressesAction |
    LoadUserShippingAdressesCompleteAction |
    LoadUserShippingAdressesFailAction |
    GetMainDistributionCentersAdressAction |
    GetMainDistributionCentersAdressCompleteAction |
    GetMainDistributionCentersAdressFailAction |
    GetAllDistributionCentersAdressAction |
    GetAllDistributionCentersAdressCompleteAction |
    GetAllDistributionCentersAdressFailAction |
    LoadAuthTokenAction |
    LoadAuthTokenCompleteAction |
    LoadAuthTokenFailAction |
    LoadAuthTokensAction |
    LoadAuthTokensCompleteAction |
    LoadAuthTokensFailAction |
    UserLogoutAction;
