<div id="content" class="mb-0">
    <div class="panel mb-0">
        <p>
            Hier haben Sie die
            Möglichkeit,
            Schnittstellen zu anderen
            Systemen zu
            konfigurieren.
        </p>
        <div class="mt-5">
            <div class="tile-container mb-l">
                <a href="/#/schnittstellen/datev">
                    <div class="tile">
                        <img #tile src="../../assets/img/integrations/datev.svg"/>
                        <h4>DATEV</h4>
                    </div>
                </a>
                <a href="/#/schnittstellen/treuhand" *ngIf="canary && canUseFeature">
                    <div class="tile">
                        <img #tile src="../../assets/img/integrations/treuhand.svg"/>
                        <h4>my.treuhand</h4>
                    </div>
                </a>
            </div>
        </div>
    </div>
</div>
