import {Component, OnDestroy, OnInit} from '@angular/core';

import {PagesRestService} from '../../../core/services/api/cms/pages.rest-service';
import {ActivatedRoute, Router} from '@angular/router';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';


@Component({
    selector: 'app-cms',
    templateUrl: './cms.component.html',
    styleUrls: ['./cms.component.scss']
})
export class CmsComponent implements OnInit, OnDestroy {
    public loading = true;

    public body = null;
    public footer = null;
    public page = null;
    public link = null;
    public ngUnsubscribe$ = new Subject<any>();

    constructor(private pageService: PagesRestService,
                private route: ActivatedRoute,
                private router: Router) {
        // this.saveRouterStrategyReuseLogic = this.router.routeReuseStrategy.shouldReuseRoute;
        // this.router.routeReuseStrategy.shouldReuseRoute = (future, curr) => {
        //     return future===curr;
        // };
    }


    ngOnInit(): void {

    this.route.params.subscribe(params => {
      this.pageService.getPage(params.url).subscribe(page => {
        this.page = page.return_object;
        if (this.page.active) {
          this.body = this.page.content;
          this.loading = false;
          this.link = params.url;
        } else {
          this.router.navigate(['/dashboard']);
        }
            });
        });
    }



    /**
     * Unsubscribe from all subscriptions.
     */
    ngOnDestroy(): void {
        // this.router.routeReuseStrategy.shouldReuseRoute = this.saveRouterStrategyReuseLogic;
        this.ngUnsubscribe$.next();
        this.ngUnsubscribe$.complete();
    
    }
}
