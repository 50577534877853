import { Injectable } from '@angular/core';
import {RestServiceAbstract} from '../../abstracts/rest-service.abstract';
import {map} from 'rxjs/operators';
import {Response} from '../../models/response.model';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RevenueRestService extends RestServiceAbstract {
  public requestRevenue(idf: string): Observable<Response> {
    return this.get(`/usermanagement/revenue/${idf}`).pipe(
        map((response: Object) => {
          return new Response(response);
        })
    );
  }
}
