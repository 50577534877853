<h1>LINDA Leistungen</h1>
<p>
  Um das Umsatzpotenzial Ihrer Apotheke maximal auszuschöpfen, müssen Kaufstruktur, Produktplatzierungen und Vermarktung optimal verzahnt sein. Und genau diese Möglichkeit bieten wir Ihnen mit den zusätzlichen LINDA Leistungen innerhalb Ihrer Kooperations-Partnerschaft. Nutzen Sie exklusive Vorteile wie den Apothekenfinder, das SPARezept und Smart QMS Leistungen.
</p>
<div>
  <div *ngIf="tokens != null && !userService.isAdmin()">
    <ng-container *ngIf="tokens.length === 1 && allowed(tokens).length === 1">
      <button class="btn btn-primary btn-linda-dark" (click)="goToLinda(tokens[0].key)">Zum LINDA Intranet
      </button>
    </ng-container>
    <ng-container *ngIf="tokens.length > 1">
      <ng-container *ngIf="allowed(tokens).length>1">
        <button class="btn btn-primary btn-linda-dark"
                (click)="openIdfSelectionDialog(
                                NavType.LINDA,
                                 'assets/img/tiles/svg/LINDA-kachel.svg',
                                 'Anmeldung im LINDA Intranet',
                                  'Bitte wählen Sie die Apotheke aus, mit der Sie sich im LINDA Intranet anmelden wollen.',
                                   allowed(tokens));trackClick('Zum LINDA Intranet')">
          Zum LINDA Intranet
        </button>
      </ng-container>
      <ng-container *ngIf="allowed(tokens).length === 1">
        <button class="btn btn-primary btn-linda-dark" (click)="goToLinda(allowed(tokens)[0].key)">
          Zum LINDA Intranet
        </button>
      </ng-container>
    </ng-container>
  </div>
  <div *ngIf="userService.isAdmin()">
    <button class="btn btn-primary btn-linda-dark"
            (click)="openIdfSelectionDialog(NavType.LINDA_ADMIN,
                         'assets/img/tiles/svg/LINDA-kachel.svg',
                         'Anmeldung im LINDA Intranet',
                          'Bitte wählen Sie die Apotheke aus, mit der Sie sich im LINDA Intranet anmelden wollen.',
                           userService.getIdfsObservable())">
      Zum LINDA Intranet
    </button>
  </div>
</div>


<div class="row mt-s">
  <div class="col-md-4">

    <img src="https://cspappsacc.blob.core.windows.net/cms/images/LINDA_SPARezept.png"
         class="img-border" alt="SPARezept">

  </div>
  <div class="col-md">
    <p class="linda-cardtitle">Ihr individueller Monatsflyer</p>
    <p>
      Wählen oder wechseln Sie zu jeder Zeit eine von drei Rabattstufen/UVP der Aktionsartikel im Rahmen Ihrer Monatsaktion aus oder legen Sie individuelle Preise fest. Der optimierte Monatsflyer bietet zudem viele weitere Individualisierungsmöglichkeiten wie bspw. Coupons, Wahl zusätzlicher Versandadressen oder die Buchung eines Verteilservices.
    </p>
    <p>
      Die dazugehörigen Monatsplakate helfen Ihnen bei der Kundenansprache in Ihrem lokalen Umfeld und dienen in Kombination mit den Flyern als perfektes Instrument zur Kundenansprache, um somit Zeit und Kosten zu sparen.
    </p>
    <div class="row mt-s">
      <div class="col-md-7 align-items-center d-flex">
        <a href="https://cspapssacc.blob.core.windows.net/cms/files/Monatsflyer_Bestellanleitung_231212.pdf" class="linda-link"
           target="_blank">Download Bestellanleitung</a>&nbsp; (4,73 MB)
      </div>
      <div class="col-md align-items-center d-flex">
        <a class="download-button btn btn-primary btn-round"
           href="https://cspapssacc.blob.core.windows.net/cms/files/Monatsflyer_Bestellanleitung_231212.pdf"
           target="_blank">
          <span class="arrow-helper far fa-arrow-down"></span>
        </a>
      </div>
    </div>
    <div class="row mt-s">
      <div class="col-md-7 align-items-center d-flex">
        <a href="https://cspappsacc.blob.core.windows.net/cms/files/Monatsflyer_Timings_301123.pdf"
           target="_blank" class="linda-link">Download Terminübersicht Monatsaktionen</a>&nbsp; (536 KB)
      </div>
      <div class="col-md align-items-center d-flex">
        <a class="download-button btn btn-primary btn-round"
           href="https://cspappsacc.blob.core.windows.net/cms/files/Monatsflyer_Timings_301123.pdf"
           target="_blank">
          <span class="arrow-helper far fa-arrow-down"></span>
        </a>
      </div>
    </div>
    <div class="row mt-s">
      <div class="col-md-7 align-items-center d-flex">
        <a href="https://cspappsacc.blob.core.windows.net/cms/files/Monatsflyer_Preisübersicht.pdf"
           target="_blank" class="linda-link">Download Monatsflyer Preisübersicht</a>&nbsp; (518 KB)
      </div>
      <div class="col-md align-items-center d-flex">
        <a class="download-button btn btn-primary btn-round"
           href="https://cspappsacc.blob.core.windows.net/cms/files/Monatsflyer_Preisübersicht.pdf"
           target="_blank">
          <span class="arrow-helper far fa-arrow-down"></span>
        </a>
      </div>
    </div>
  </div>
</div>
<div class="row mt-l">
  <div class="col-md-4">

    <img src="https://cspappsacc.blob.core.windows.net/cms/images/LINDA_apothekenfinder.png"
         class="img-border" alt="Apothekenfinder">

  </div>
  <div class="col-md">
    <p class="linda-cardtitle">Apothekenfinder</p>
    <p>
      Hinterlegen Sie Ihre Stammdaten und lassen Sie sich von Ihrer Kundschaft noch schneller und einfach finden. Durch das Eintragen von Stadt, PLZ oder Adresse ist Ihre Apotheke in der Nähe des Suchenden aufgeführt und führt diesen direkt zu Ihnen.
    </p>
  </div>
</div>
<div class="row mt-s">
  <div class="col-md-4">

    <img src="https://cspappsacc.blob.core.windows.net/cms/images/LINDA_Smart_QMS_Leistungen.png"
         class="img-border" alt="Smart QMS Leistungen">

  </div>
  <div class="col-md">
    <p class="linda-cardtitle">Smart QMS Leistungen</p>
    <p>
      Die Themen Qualität und Qualitätssicherung von Produkten und Dienstleistungen sind fest mit dem Apothekenumfeld verbunden. Ein zuverlässiges und anwenderfreundliches Qualitätsmanagementsystem (QMS) ist dabei unerlässlich. Unser erklärtes Ziel ist es, gemeinsam mit Ihnen, die Leistungen im Sinne Ihrer Anforderungen und der Kundenbedürfnisse stetig zu verbessern und den Arbeitsalltag in den Apotheken in Effektivität und Effizienz zu unterstützen.
    </p>
  </div>
</div>


<div>
  <div *ngIf="tokens != null && !userService.isAdmin()">
    <ng-container *ngIf="tokens.length === 1 && allowed(tokens).length === 1">
      <button class="btn btn-primary btn-linda-dark" (click)="goToLinda(tokens[0].key)">Zum LINDA Intranet
      </button>
    </ng-container>
    <ng-container *ngIf="tokens.length > 1">
      <ng-container *ngIf="allowed(tokens).length>1">
        <button class="btn btn-primary btn-linda-dark"
                (click)="openIdfSelectionDialog(
                                NavType.LINDA,
                                 'assets/img/tiles/svg/LINDA-kachel.svg',
                                 'Anmeldung im LINDA Intranet',
                                  'Bitte wählen Sie die Apotheke aus, mit der Sie sich im LINDA Intranet anmelden wollen.',
                                   allowed(tokens));trackClick('Zum LINDA Intranet')">
          Zum LINDA Intranet
        </button>
      </ng-container>
      <ng-container *ngIf="allowed(tokens).length === 1">
        <button class="btn btn-primary btn-linda-dark" (click)="goToLinda(allowed(tokens)[0].key)">
          Zum LINDA Intranet
        </button>
      </ng-container>
    </ng-container>
  </div>
  <div *ngIf="userService.isAdmin()">
    <button class="btn btn-primary btn-linda-dark"
            (click)="openIdfSelectionDialog(NavType.LINDA_ADMIN,
                         'assets/img/tiles/svg/LINDA-kachel.svg',
                         'Anmeldung im LINDA Intranet',
                          'Bitte wählen Sie die Apotheke aus, mit der Sie sich im LINDA Intranet anmelden wollen.',
                           userService.getIdfsObservable())">
      Zum LINDA Intranet
    </button>
  </div>
</div>
