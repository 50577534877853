import {InvoicesActions, InvoicesActionTypes} from '../actions/invoices.actions';
import {InvoicesRequest} from '../../models/invoices-request.model';
import {InvoicesResponse} from '../../models/invoices-response.model';
import {HttpErrorResponse} from '@angular/common/http';
import {DocTypesRequest} from '../../models/doc-types-request.model';
import {DocTypes} from '../../models/doc-types.model';
import {DocType} from '../../models/doc-type.model';


export interface State {
    selectedBranchesForInvoiceRequest: any[];
    invoicesRequest: InvoicesRequest;
    invoicesResponse: InvoicesResponse;
    invoicesErrorResponse: HttpErrorResponse;
    docTypesRequest: DocTypesRequest;
    allDocTypesRequest: DocTypesRequest;
    invoicesSearching: boolean;
    docTypesLoading: boolean;
    allDocTypesLoading: boolean;
    docTypes: Map<string, DocTypes>;
    allDocTypes: DocType[];
    showInvoiceContactForm: boolean;
}

export const initialState: State = {
    selectedBranchesForInvoiceRequest: null,
    invoicesRequest: null,
    invoicesResponse: null,
    invoicesErrorResponse: null,
    invoicesSearching: false,
    docTypesRequest: null,
    allDocTypesRequest: null,
    docTypesLoading: false,
    allDocTypesLoading: false,
    docTypes: null,
    allDocTypes: null,
    showInvoiceContactForm: false
};

export function reducer(state = initialState, action: InvoicesActions): State {
    switch (action.type) {
        case InvoicesActionTypes.SET_BRANCHES_FOR_INVOICE_SEARCH:
            return {
                ...state,
                selectedBranchesForInvoiceRequest: action.payload
            };
        case InvoicesActionTypes.SEARCH_INVOICES:
            return {
                ...state,
                invoicesRequest: action.payload,
                invoicesSearching: true
            };
        case InvoicesActionTypes.SEARCH_INVOICES_COMPLETE:
            const branchId = state.invoicesRequest.branch;
            let updatedInvoiceResponse = {...state.invoicesResponse};
            if (updatedInvoiceResponse.results) {
                const results = {...state.invoicesResponse.results};
                results[branchId] = action.payload.results[branchId];
                updatedInvoiceResponse.results = results;
            } else {
                updatedInvoiceResponse = action.payload;
            }
            return {
                ...state,
                invoicesResponse: updatedInvoiceResponse,
                invoicesSearching: false
            };
        case InvoicesActionTypes.SEARCH_INVOICES_FAIL:
            return {
                ...state,
                invoicesResponse: action.payload,
                invoicesSearching: false
            };
        case InvoicesActionTypes.RESET_SEARCH_INVOICES_RESPONSE:
            const updatedInvoicesResponse = {...state.invoicesResponse};
            updatedInvoicesResponse.results = {};
            return {
                ...state,
                invoicesResponse: null
            };
        case InvoicesActionTypes.LOAD_DOC_TYPES:
            return {
                ...state,
                docTypesLoading: true
            };
        case InvoicesActionTypes.LOAD_DOC_TYPES_COMPLETE:
            const dTypes: any = [];
            for (const key in action.payload) {
                if (action.payload.hasOwnProperty(key)) {
                    action.payload[key].documentType['distributionCenters'] = action.payload[key].distributionCenters;
                    dTypes.push(action.payload[key].documentType);
                }
            }
            const dt = {docTypes: dTypes};
            const docTypes = !state.docTypes ?  new Map<string, DocTypes>() : state.docTypes;
            // TODO: use service to create key
            docTypes.set(action.request.idf + '#' + action.request.dc, new DocTypes({...action.request, ...dt}));
            return {
                ...state,
                docTypesRequest: action.request,
                docTypesLoading: false,
                docTypes: docTypes
            };
        case InvoicesActionTypes.LOAD_DOC_TYPES_FAIL:
            return {
                ...state,
                invoicesErrorResponse: action.payload,
                docTypesLoading: false
            };
        case InvoicesActionTypes.LOAD_ALL_DOC_TYPES:
            return {
                ...state,
                allDocTypesLoading: true
            };
        case InvoicesActionTypes.LOAD_ALL_DOC_TYPES_COMPLETE:
            return {
                ...state,
                allDocTypes: action.payload,
                allDocTypesLoading: false
            };
        case InvoicesActionTypes.LOAD_ALL_DOC_TYPES_FAIL:
            return {
                ...state,
                invoicesErrorResponse: action.payload,
                allDocTypesLoading: false
            };
        case InvoicesActionTypes.SHOW_INVOICE_CONTACT_FORM_VIEW:
            return {
                ...state,
                showInvoiceContactForm: true
            };
        case InvoicesActionTypes.HIDE_INVOICE_CONTACT_FORM_VIEW:
            return {
                ...state,
                showInvoiceContactForm: false
            };
        default:
            return state;
    }
}

export const getSelectedBranchesForInvoiceRequest = (state: State) => state.selectedBranchesForInvoiceRequest;
export const getInvoicesRequest = (state: State) => state.invoicesRequest;
export const getInvoicesResponse = (state: State) => state.invoicesResponse;
export const getInvoicesSearching = (state: State) => state.invoicesSearching;
// export const getDocTypesRequest = (state: State) => state.docTypesRequest;
export const getDocTypesLoading = (state: State) => state.docTypesLoading;
export const getDocTypes = (state: State) => state.docTypes;
export const getAllDocTypes = (state: State) => state.allDocTypes;
export const getAllDocTypesLoading = (state: State) => state.allDocTypesLoading;
export const getContactFormVisibility = (state: State) => state.showInvoiceContactForm;

