<div class="productSearchDialogContainer">
    <img src="{{imageUrl}}"
         data-content-piece alt="{{title}}">
    <div class="p-4">
        <h2 mat-dialog-title class="mt-2 pb-2">
            {{title}}
        </h2>
        <div mat-dialog-content>
            <p [innerHTML]="message"></p>
        </div>
        <div mat-dialog-actions class="d-flex justify-content-center mb-1">
            <button type="button" class="btn btn-primary" mat-button [mat-dialog-close]="true" cdkFocusInitial><span>JETZT ENTDECKEN</span></button>
        </div>
    </div>
</div>


