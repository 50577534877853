import {UserActions, UserActionTypes} from '../actions/user.actions';
import {UserResponse} from '../../models/user-response.model';
import {HttpErrorResponse} from '@angular/common/http';
import {IdfBranches} from '../../models/idf-branches.model';
import {Adress} from '../../models/adress.model';
import {Nav} from '../../models/nav.model';


export interface State {
    userResponse: UserResponse;
    nav: Nav;
    vzs: IdfBranches[];
    idfs: any;
    contextIdfs: string[];
    mainIdf: string;
    mainIdfLoading: boolean;
    mainDistributionCenterAdress: any;
    mainDistributionCenterAdressLoading: boolean;
    allDistributionCenterAdress: any;
    allDistributionCenterAdressLoading: boolean;
    userErrorResponse: HttpErrorResponse;
    userLoading: boolean;
    userIDFsLoading: boolean;
    userVZsLoading: boolean;
    navLoading: boolean;
    shippingAdresses: Adress[];
    shippingAdressesLoading: boolean;
    authToken: string;
    authTokenLoading: boolean;
    // TODO define data type
    authTokens: any;
    authTokensLoading: boolean;
}

export const initialState: State = {
    userResponse: null,
    nav: null,
    vzs: null,
    idfs: null,
    contextIdfs: null,
    mainDistributionCenterAdress: null,
    mainDistributionCenterAdressLoading: false,
    allDistributionCenterAdress: null,
    allDistributionCenterAdressLoading: false,
    mainIdf: null,
    mainIdfLoading: false,
    userErrorResponse: null,
    userLoading: false,
    userIDFsLoading: false,
    userVZsLoading: false,
    navLoading: false,
    shippingAdresses: null,
    shippingAdressesLoading: null,
    authToken: null,
    authTokenLoading: null,

    authTokens: null,
    authTokensLoading: false,
};

export function reducer(state = initialState, action: UserActions): State {
    switch (action.type) {
        case UserActionTypes.LOAD_USER:
            return {
                ...state,
                userLoading: true
            };
        case UserActionTypes.LOAD_USER_COMPLETE:
            return {
                ...state,
                userResponse: action.payload,
                userLoading: false
            };
        case UserActionTypes.LOAD_USER_FAIL:
            return {
                ...state,
                userErrorResponse: action.payload,
                userLoading: false
            };
        case UserActionTypes.LOAD_NAV:
            return {
                ...state,
                navLoading: true
            };
        case UserActionTypes.LOAD_NAV_COMPLETE:
            return {
                ...state,
                nav: new Nav(action.payload.data, action.payload.url, action.payload.vaccineConfig),
                navLoading: false
            };
        case UserActionTypes.LOAD_NAV_FAIL:
            return {
                ...state,
                // navErrorResponse: action.payload,
                nav: new Nav(null, null, action.payload.vaccineConfig),
                navLoading: false
            };
        case UserActionTypes.LOAD_USER_IDFS:
            return {
                ...state,
                ...{userIDFsLoading: true}
            };
        case UserActionTypes.LOAD_USER_IDFS_COMPLETE:
            // const mainIdf = action.payload.idfs[0];
            const idfs = [action.payload.context];
            idfs[action.payload.context] = action.payload.idfs;
            return {
                ...state,
                idfs: {...state.idfs, ...idfs},
                // mainIdf: mainIdf,
                userIDFsLoading: false
            };
        case UserActionTypes.LOAD_USER_IDFS_FAIL:
            return {
                ...state,
                userErrorResponse: action.payload,
                userIDFsLoading: false
            };
        case UserActionTypes.LOAD_USER_MAIN_IDF:
            return {
                ...state,
                mainIdfLoading: true
            };
        case UserActionTypes.LOAD_USER_MAIN_IDF_COMPLETE:
            return {
                ...state,
                mainIdfLoading: false,
                mainIdf: action.payload
            };
        case UserActionTypes.LOAD_USER_MAIN_IDF_FAIL:
            return {
                ...state,
                userErrorResponse: action.payload,
                mainIdfLoading: false
            };
        case UserActionTypes.LOAD_USER_VZS:
            return {
                ...state,
                userVZsLoading: true
            };
        case UserActionTypes.LOAD_USER_VZS_COMPLETE:
            //state.vzs = !state.vzs ? [] : state.vzs;
            const vz = new IdfBranches({idf: action.idf, branches: action.payload});
            if (!state.vzs) {
                const emptyVzs = [];
                return {
                    ...state,
                    userVZsLoading: false,
                    vzs: [...emptyVzs, vz]
                };
            }
            else {
                return {
                    ...state,
                    userVZsLoading: false,
                    vzs: [...state.vzs, vz]
                };
            }
        case UserActionTypes.LOAD_USER_VZS_FAIL:
            return {
                ...state,
                userErrorResponse: action.payload,
                userVZsLoading: false
            };
        case UserActionTypes.LOAD_USER_SA:
            return {
                ...state,
                shippingAdressesLoading: true
            };
        case UserActionTypes.LOAD_USER_SA_COMPLETE:
            return {
                ...state,
                shippingAdressesLoading: false,
                shippingAdresses: action.payload
            };
        case UserActionTypes.LOAD_USER_SA_FAIL:
            return {
                ...state,
                userErrorResponse: action.payload,
                shippingAdressesLoading: false
            };
        case UserActionTypes.LOAD_USER_DISTRIBUTION_CENTER_ADRESS:
            return {
                ...state,
                mainDistributionCenterAdressLoading: true
            };
        case UserActionTypes.LOAD_USER_DISTRIBUTION_CENTER_ADRESS_COMPLETE:
            return {
                ...state,
                mainDistributionCenterAdressLoading: false,
                mainDistributionCenterAdress: action.payload
            };
        case UserActionTypes.LOAD_USER_DISTRIBUTION_CENTER_ADRESS_FAIL:
            return {
                ...state,
                userErrorResponse: action.payload,
                mainDistributionCenterAdressLoading: false
            };
        case UserActionTypes.LOAD_ALL_USER_DISTRIBUTION_CENTER_ADRESS:
            return {
                ...state,
                allDistributionCenterAdressLoading: true
            };
        case UserActionTypes.LOAD_ALL_USER_DISTRIBUTION_CENTER_ADRESS_COMPLETE:
            return {
                ...state,
                allDistributionCenterAdressLoading: false,
                allDistributionCenterAdress: action.payload
            };
        case UserActionTypes.LOAD_ALL_USER_DISTRIBUTION_CENTER_ADRESS_FAIL:
            return {
                ...state,
                userErrorResponse: action.payload,
                allDistributionCenterAdressLoading: false
            };
        case UserActionTypes.LOAD_AUTH_TOKEN:
            return {
                ...state,
                authTokenLoading: true
            };
        case UserActionTypes.LOAD_AUTH_TOKEN_COMPLETE:
            return {
                ...state,
                authTokenLoading: false,
                authToken: action.payload
            };
        case UserActionTypes.LOAD_AUTH_TOKEN_FAIL:
            return {
                ...state,
                userErrorResponse: action.payload,
                authTokenLoading: false
            };
        case UserActionTypes.LOAD_AUTH_TOKENS:
            return {
                ...state,
                authTokensLoading: true
            };
            // TODO contextualization is not yet implemented, do so!
        case UserActionTypes.LOAD_AUTH_TOKENS_COMPLETE:
            return {
                ...state,
                authTokens: JSON.parse(action.payload),
                authTokensLoading: false
            };
        case UserActionTypes.LOAD_AUTH_TOKENS_FAIL:
            return {
                ...state,
                userErrorResponse: action.payload,
                authTokensLoading: false
            };
        case UserActionTypes.LOGOUT:
            state = undefined;
            return {
                ...state
            };
        default:
            return state;
    }
}

export const getUserResponse = (state: State) => state.userResponse;
export const getUserErrorResponse = (state: State) => state.userErrorResponse;
export const getUserLoading = (state: State) => state.userLoading;
export const getNav = (state: State) => state.nav;
export const getNavLoading = (state: State) => state.navLoading;
export const getUserVZsLoading = (state: State) => state.userVZsLoading;
export const getUserIDFsLoading = (state: State) => state.userIDFsLoading;
export const getUserVZs = (state: State) => state.vzs;
export const getUserIDFs = (state: State) => state.idfs;
export const getUserMainIdf = (state: State) => state.mainIdf;
export const getUserMainIdfLoading = (state: State) => state.mainIdfLoading;
export const getUserShippingAdresses = (state: State) => state.shippingAdresses;
export const getUserShippingAdressesLoading = (state: State) => state.shippingAdressesLoading;
export const getMainDistributionCentersAdress = (state: State) => state.mainDistributionCenterAdress;
export const getMainDistributionCentersAdressLoading = (state: State) => state.mainDistributionCenterAdressLoading;
export const getAllDistributionCentersAdress = (state: State) => state.allDistributionCenterAdress;
export const getAllDistributionCentersAdressLoading = (state: State) => state.allDistributionCenterAdressLoading;
export const getAuthToken = (state: State) => state.authToken;
export const getAuthTokenLoading = (state: State) => state.authTokenLoading;
export const getAuthTokens = (state: State) => state.authTokens;
export const getAuthTokensLoading = (state: State) => state.authTokensLoading;
