<div (focusin)="activate.emit(product.id)" (focusout)="deactivate.emit(product.id)" class="cart-provider" *ngIf="product">
    <form [formGroup]="addToCartForm">
        <ng-container *ngIf="product.packageProducts.length > 1 && router.url.includes('product-details')">
            <h4 class="my-2">Packungsgröße</h4>
            <div [ngClass]="product.packageProducts.length > 3 ? 'box' : 'd-flex'">
                <span class="packBox text-center"
                      [class.selectedPackage]="product.id === pack.pzn"
                      [class.notAvailable]="!pack.available"
                      *ngFor="let pack of sortPackage(product.packageProducts)"
                      (click)="product.id !== pack.pzn && pack.available ? loadProduct(pack.pzn) : null"
                >
                    {{pack.amountText}}
                </span>
            </div>
        </ng-container>
        <div class="quantity" [class.active]="active && product.retailPrice !== 0">
            <div class="buttons">
            <button type="button" class="btn-minus" (click)="decreaseQuantity()"><i class="fal fa-minus"></i></button>
            <button type="button" class="btn-plus" (click)="increaseQuantity()"><i class="fal fa-plus"></i></button>
            </div>
            <div class="input {{product.available || product.promotion ? 'available' : 'not-available'}}">
                <input formControlName="quantityInput" type="number" min="1" max="999" step="1" value="{{quantity}}" (blur)="updateQuantity($event)" (change)="updateQuantity($event)" data-cy="add-to-cart-quantity-input">
            </div>
            <div class="availability {{product.available || product.promotion ? 'available' : 'not-available'}}">
                {{product.available || product.promotion ? 'verfügbar' : 'nicht verfügbar'}}
                <i class="fal {{product.available || product.promotion ? 'fa-check' : 'fa-ban'}} float-right p-1"></i>
            </div>
        </div>
        <button *ngIf="product.retailPrice > 0"
                class="btn-primary add-to-cart-button d-inline-block" type="submit" (click)="addToCart()"
                attr.data-cy="add-to-cart-{{product.id}}">In den Warenkorb</button>
        <button *ngIf="product.retailPrice === 0"
                class="btn-primary inactive add-to-cart-button d-inline-block"
                attr.data-cy="add-to-cart-{{product.id}}">In den Warenkorb</button>
    </form>
</div>
