import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ROUTES} from './view.routes';
import {ViewComponent} from './view.component';
import {NavComponent} from './nav/nav.component';
import {HeaderComponent} from './header/header.component';
import {ErrorComponent} from './error/error.component';
import { MatBadgeModule } from '@angular/material/badge';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTooltipModule } from '@angular/material/tooltip';
import {CopyPasteElementsComponent} from '../copy-paste-elements/copy-paste-elements.component';
import {CartProviderModule} from '../modules/webshop/components/cart/cart-provider/cart-provider.module';
import {AddToCartNotifierComponent} from '../modules/webshop/components/cart/add-to-cart-notifier/add-to-cart-notifier.component';
import {NotificationModule} from '../modules/shared/notification/notification.module';
import {QuickNavComponent} from './quick-nav/quick-nav.component';
import {MySalesComponent} from '../modules/account/pages/my-sales/my-sales.component';
// import {AgmCoreModule} from '@agm/core';
import {FooterModule} from './footer/footer.module';
import {TranslateModule} from '@ngx-translate/core';
import {NgxEchartsModule} from 'ngx-echarts';
import {SpinnerModule} from '../modules/shared/spinner/spinner.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatCardModule} from '@angular/material/card';
import {AdsComponent} from './ads/ads.component';
import {SafehtmlModule} from '../modules/shared/safehtml/safehtml.module';
import { NoticesComponent } from './notices/notices.component';
import { DialogPopupComponent } from './dialog-popup/dialog-popup.component';


// @ts-ignore
@NgModule({
    imports: [
        /*AgmCoreModule.forRoot({
            apiKey: 'AIzaSyDsqZJGhUk4dUqSYmqLp805yZGsxdISRJg'
             apiKey is required, unless you are a
            premium customer, in which case you can
            use clientId
        }),*/
        CartProviderModule,
        CommonModule,
        FooterModule,
        FormsModule,
        MatBadgeModule,
        MatDialogModule,
        MatFormFieldModule,
        MatFormFieldModule,
        MatInputModule,
        MatRadioModule,
        MatSelectModule,
        MatStepperModule,
        MatTooltipModule,
        NgxEchartsModule,
        NotificationModule,
        ROUTES,
        ReactiveFormsModule,
        SpinnerModule,
        TranslateModule,
        MatCardModule,
        SafehtmlModule
    ],
    declarations: [
        AddToCartNotifierComponent,
        CopyPasteElementsComponent,
        ErrorComponent,
        HeaderComponent,
        MySalesComponent,
        NavComponent,
        QuickNavComponent,
        ViewComponent,
        AdsComponent,
        NoticesComponent,
        DialogPopupComponent
    ],
    exports: [
        ViewComponent,
        NoticesComponent
    ]
})
export class ViewModule {
}
