<article class="content">
    <div class="panel">
        <div class="row justify-content-center align-items-center d-flex">
            <div class="col-md-8 col-sm-8 d-flex flex-row align-items-center">
                <img src="assets/img/integrations/datev.svg" alt="Datev"
                     style="max-height: 60px">
                <h1 class="p-0 ml-4">DATEV</h1>
            </div>
            <div class="col-md-4 col-sm-4 ">
                <form [formGroup]="userForm">
                    <mat-form-field *ngIf="!input" appearance="outline">
                        <mat-label>IDF-Nummer</mat-label>
                        <mat-select formControlName="idfInput" (selectionChange)="setIdf($event)">
                            <mat-option *ngIf="(idfs$ | async)?.length === 0">
                                <i class="fal fa-spinner-third fa-spin"></i> IDFs werden geladen.
                            </mat-option>
                            <mat-option *ngFor="let idf of (idfs$ | async)" [value]="idf.idf">{{ idf.idf }}
                                ({{idf.name}})
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field *ngIf="input" appearance="outline">
                        <mat-label>IDF-Nummer</mat-label>
                        <input [value]="selectedIdf" matInput formControlName="idfInput">
                    </mat-form-field>
                </form>
            </div>
        </div>
        <div class="mt-5">
            <div *ngIf="loading" class="text-center py-l">
                <i class="fal fa-spinner-third fa-spin"></i> Laden...
            </div>
            <app-datev-form [idf]="selectedIdf" [mainIdf]="mainIdf"></app-datev-form>
        </div>
    </div>
</article>
